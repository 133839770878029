$primary-color: #7795FF;
$resale-green: #00cfbe;
$background-color: #f9f9f9;
$white-color: #ffffff;
$header-color: $white-color;

$title-color: #2e325e;
$grey-text: #676b8b;
$text-color:#454545;

$line-color: #dadade;

$font-size-small: 14px;
$font-size: 16px;
$font-size-med: 18px;
$font-size-lg: 20px;

$padding-global-left: 55px;
$padding-global-right: 45px;
$font-family: sans-serif;

// Adicionar familia de fonte