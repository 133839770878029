@import "../../assets/scss/variables.scss";

article {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid black;
  margin: 1em;
  padding: 1em;
  width: 20%;
}